import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import dinkha from "../../images/william-dinkha.svg"

const AboutSecond = () => {
  return (
    <div>
      <div className="section sub-container">
        <div className="flex space-between">
          <div style={{ width: "50%" }}>
            <StaticImage src="../../images/about.png" alt="about" />
          </div>
          <div>
            <h6>About Us</h6>
            <h3>Dinkha Dental</h3>
            <p style={{ maxWidth: "580px" }}>
              With Dinkha Dental, La Mesa residents are guaranteed
              top-of-the-line dental services for the best smiles. We offer the
              highest quality of customer care — maintaining transparency and
              always ready to answer any questions — to ensure each of our
              clients undergo their procedures fully informed and comfortable.
            </p>
            <div className="flex align-center">
              <StaticImage
                src="../../images/tick.png"
                alt="tick mark"
                className="tick"
              />{" "}
              <p>Dental Implants</p>
            </div>
            <div className="flex align-center">
              <StaticImage
                src="../../images/tick.png"
                alt="tick mark"
                className="tick"
              />{" "}
              <p>Emergency Dentistry</p>
            </div>
            <div className="flex align-center">
              <StaticImage
                src="../../images/tick.png"
                alt="tick mark"
                className="tick"
              />{" "}
              <p>Veneers</p>
            </div>
            <div className="flex align-center">
              <StaticImage
                src="../../images/tick.png"
                alt="tick mark"
                className="tick"
              />{" "}
              <p>Family Dentistry</p>
            </div>
            <div className="flex align-center">
              <StaticImage
                src="../../images/tick.png"
                alt="tick mark"
                className="tick"
              />{" "}
              <p>Cosmetic Dentistry</p>
            </div>
          </div>
        </div>
        <div className="flex space-between section">
          <div>
            <h6>Comprehensive Excellence</h6>
            <h3>Our Doctors</h3>
            <p style={{ maxWidth: "680px" }}>
              Dr. Dinkha is motivated and determined to apply his vast
              experience in dentistry to provide the highest quality of dental
              care. After graduating from Christian High School, he attended San
              Diego State University. After which, he went on to study dentistry
              at one of the most prestigious East Coast dental schools, Tufts
              University.
            </p>
            <p style={{ maxWidth: "680px" }}>
              Dr. Dinkha graduated from Tufts in 1994 and eagerly began his
              career. He, now, has over 26 years of experience as a dentist. Dr.
              Dinkha’s family-run dental office, Dinkha Dental in La Mesa, has
              been serving the San Diego community for over 17 years, and Dr.
              Dinkha hopes you truly feel welcomed and cared for at Dinkha
              Dental.
            </p>
          </div>
          <div
            className="flex column align-center"
            style={{ width: "30%", paddingLeft: "40px" }}
          >
            <img src={dinkha} alt="william dinkha" />
            <h6>Dr William Dinkha</h6>
            <p className="" style={{ color: "black" }}>
              Our Dentist
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutSecond
