import React from "react"
import AboutSecond from "../components/about/about.component"
import BannerAbout from "../components/about/banner/banner.component"
import Seo from "../components/seo"
import Layout from "../components/layout"
import ServicesComponent from "../components/services.components"

const About = () => {
  return (
    <div className="">
      <Layout>
        <Seo title="About" />
        <BannerAbout />
        <AboutSecond />
        <ServicesComponent />
      </Layout>
    </div>
  )
}

export default About
